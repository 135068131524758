<template>
  <div
    class="indicator  "
    :class="show ? 'indicator--activ' : ''"
  ></div>
</template>

<script>
export default {
  name: 'RequestIndicator',
  props: {
    show: Boolean
  }
}
</script>
 
<style scoped lang="scss">
.indicator {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 10px; 
  background: linear-gradient(to right, rgb(4, 63, 92), #fff 30px, rgb(4, 63, 92) 60px) no-repeat -100px
      0/100px 100%,
    rgb(4, 63, 92);
  animation: move 1s linear infinite;
  visibility: hidden;
  &--activ {
    visibility: visible;
  }
}
@keyframes move {
  from {
    background-position: -100px 0;
  }
  to {
    background-position: 120% 0;
  }
}
</style>
