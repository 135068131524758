<template>
  <RequesIndicator :show="requestExecution" />
  <div class="login">
    <div class="login__content">
      <div class="login__head">
        <div class="login__languages">
          <button
            v-for="item of langMenu"
            :key="item"
            @click="setLanguage(item)"
            class="login__languages-link"
            :class="item == lang ? 'login__languages-link--active' : ''"
            type="button"
          >
            {{ item }}
          </button>
        </div>
        <div class="login__logo">
          <img src="../assets/img/dt-logo-login.svg" alt="" />
        </div>
        <h1 class="login__title">{{ translate("title_page", dictionary) }}</h1>
      </div>
      <form class="login__form">
        <div class="login__form-row">
          <label class="login__form-label">Login</label>
          <input
            class="login__form-input login__form-input-user"
            name="login"
            :value="form.login"
            @input="setValueForm($event, 'login')"
            type="text"
            placeholder="Login"
          />
        </div>
        <div class="login__form-row">
          <label class="login__form-label">Password</label>
          <input
            class="login__form-input login__form-input-password"
            name="pass"
            :value="form.password"
            @input="setValueForm($event, 'password')"
            type="password"
            placeholder="Password"
          />
        </div>
        <button
          class="login__btn"
          type="button"
          @click="sendFormLogin"
          :disabled="requestExecution"
        >
          Sign in
        </button>
      </form>
      <div class="login__form-error">
        {{ translate(errorFormLogin, dictionary) }}
      </div>
    </div>
  </div>
</template>

<script>
import dictionaryLogin from "@/assets/file-storage/dictionary/login.json";
import { translate } from "@/utils/mixins/index.js";
import { mapGetters, mapActions } from "vuex";
import http from "../http-client/index.js";
import RequesIndicator from "@/components/RequestIndicator.vue";

export default {
  name: "LoginView",
  mixins: [translate],
  components: {
    RequesIndicator,
  },
  data() {
    return {
      dictionary: { ...dictionaryLogin },
      langMenu: ["ua", "en", "ru"],
      form: {
        login: "",
        password: "",
        timezone: "",
        timezone_offset: "",
      },
      errorFormLogin: "",
      requestExecution: false,
    };
  },
  created() {
    this.checkSavedLanguage();
    document.title = 'DeltaCloud';
  },
  computed: {
    ...mapGetters({ lang: "global/language" }),
  },
  methods: {
    ...mapActions({ setStorageLang: "global/setLang" }),
    setLanguage(lang) {
      localStorage.setItem("language", lang);
      this.setStorageLang(lang);
    },
    checkSavedLanguage() {
      let lang = localStorage.getItem("language");
      if (lang != null && this.langMenu.includes(lang)) {
        this.setStorageLang(lang);
      }
    },
    setValueForm(e, key) {
      this.form[key] = e.target.value.trim();
    },
    loginFormValidation(e) {
      this.errorFormLogin = "";
      if (this.form.login == "" || this.form.password == "") {
        this.errorFormLogin = "error_validation_form_login";
        return false;
      }
      return true;
    },
    async sendFormLogin(e) {
      let formValidation = this.loginFormValidation();
      if (formValidation) {
        this.requestExecution = true;
        this.form.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let offset = new Date().getTimezoneOffset() * -1;
        this.form.timezone_offset = Math.floor(offset / 60);
        let formData = {
          login: this.form.login,
          password: this.form.password,
          timezone: this.form.timezone,
          timezone_offset: this.form.timezone_offset,
        };
        let res = await http
          .post("/api/", formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
          })
          .catch((error) => {
            // console.log("error http client::::");
            this.errorFormLogin = "error_login_http_client";
            this.requestExecution = false;
          });

        if (res && res.data) {
          this.requestExecution = false;
          if (res.data.url && res.data.url != "") {
            window.location = res.data.url;
          } else if (res.data.url == "") {
            this.errorFormLogin = res.data.message;
          }
        } else {
          this.requestExecution = false;
          this.errorFormLogin = "error_login_http_client";
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
