export default {
    methods: {
        translate: function (text, dictionary = null, quiet = false) {
            if (dictionary != null && typeof dictionary[text] != 'undefined') {
                return dictionary[text][this.$store.state.global.language]
            }
            else {
                if (quiet) {
                    return ''
                }
                else {
                    console.log('Unknown word in dictionary: ' + text);
                    return text
                }
            }
        },
    }
}