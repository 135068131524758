export default {
    namespaced: true,
    state: {
        language: 'en',

    },
    getters: {
        language(state){
            return state.language;
        }
    },
    mutations: {
        setLang(state, data) {
            state.language = data;
        }
    },
    actions: {
        setLang({commit}, data) {
            commit('setLang', data);
        }
    }
};