import { createStore } from 'vuex'

import global from './modules/global.js'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    global
  }
})
